import React, {useEffect, useState} from 'react';

import {loadCaseStudiesFromCategory, loadAllCaseStudies, loadCategories} from "../common/Common";

import Loading from "../components/Loading";
import TalkToUs from "../components/TalkToUs";
import {Link} from "react-router-dom";

function CaseStudies() {
    const [categories, setCategories] = useState([]),
        [selectedCategory, setSelectedCategory] = useState(null),
        [loadingContent, setLoadingContent] = useState(true),
        [windowWidth, setWindowWidth] = useState(0),
        [caseStudies, setCaseStudies] = useState([]),
        [loadingCaseStudyContent, setLoadingCaseStudyContent] = useState(true);

    useEffect(() => {
        loadCategories().then(data => {
            setCategories(data);
            if (data.length > 0) {
                setSelectedCategory(data[0]);
            }
            setLoadingContent(false);
        }).catch(() => null);
        loadAllCaseStudies().then(data => {
            setCaseStudies(data);
            setLoadingCaseStudyContent(false);
        }).catch(() => null);
    }, []);
    const mobileBreakpoint = 678;
    const isMobile = windowWidth < mobileBreakpoint
    const caseStudyBody = () => {
        let body = [];
        if (caseStudies) {
            const numCaseStudies = caseStudies.length;
    
            // Divide the case studies into two equal parts
            const firstHalf = caseStudies.slice(0, Math.ceil(numCaseStudies / 2));
            const secondHalf = caseStudies.slice(Math.ceil(numCaseStudies / 2));
    
            // Function to render a column of case studies
            const renderColumn = (studies) => {
                return (
                    <div className="column col-spc-csd">
                        {studies.map((caseStudy, index) => (
                            <div className="d-flex flex-row justify-content-start csd gap-5 ms-5 spc-flex-case-study" key={"caseStudy" + index}>
                                <div className="d-block-inline cse-txt">
                                    <h6 className="case-study-category" style={{
                                        color: caseStudy.category.color,
                                        backgroundColor: caseStudy.category.color + "10",
                                        border: caseStudy.category.color
                                    }}>
                                        {/* {caseStudy.category_tag} */}
                                    </h6>
                                    <h3 className="case-study-name">{caseStudy.heading}</h3>
                                    <p className="case-study-description mt-4">{caseStudy.description}</p>
                                </div>
                                <Link className="d-block spc-relative" to={"/case-studies/" + caseStudy.heading}>
                                    <img src={caseStudy.header_image_url} alt={caseStudy.heading} className="w-100 float-end case-study-image" />
                                    <h4 className='abs-txt-spc'>View Project</h4>
                                </Link>
                            </div>
                        ))}
                    </div>
                );
            };
    
            // Render the columns
            body.push(
                <div className="d-flex justify-content-between spc-render-main" key="columns">
                    {renderColumn(firstHalf)}
                    {renderColumn(secondHalf)}
                </div>
            );
        }
        return body;
    }


    if (!loadingContent) {
        return (
            <div>
                <div className="panel-page-format">
                    <div className="capabilities-header-container about-us-header-container"
                         style={{paddingTop: "25vh"}}>
                        <div className="container">
                            <div>
                                <h1 className="about-title mb-4 gg-name-hdr spc-mob-top-adder">
                                    Innovative Solutions, Real Results.
                                </h1>
                                <p className="about-subtitle spc-txt-sub gg-sub-spc">
                                    Take a look at our case studies and witness the difference we've made to the brands
                                    we've worked with in the past.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{marginTop: "00vh"}}>
                        <div className='flx-case-wrapper spc-2'>
                                {loadingCaseStudyContent ?
                                    <Loading/>
                                    : caseStudyBody()
                                }
                        </div>
                    </div>
                </div>
                <TalkToUs/>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default CaseStudies
