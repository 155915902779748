import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import HomePage from './views/HomePage';
import Footer from './components/Footer'
import AboutUs from './views/AboutUs';
import AboutUs2 from './views/AboutNew';
import Blog from './views/Blog';
import Capabilities from './views/Capabilities';
import ContactUs from './views/ContactUs';
import CaseStudies from './views/CaseStudies';
import CaseStudyDescription from './views/CaseStudyDescription';
import BlogDescription from './views/BlogDescription';
import PrivacyPolicy from './views/PrivacyPolicy';
import {useState} from "react";

//import Header2 from './components/Header2';

function App() {
    const [count, setCount] = useState(0);

    const updateCount = () => {
        setCount(count + 1);
    }

    return (
        <Router>
            <ScrollToTop>
                <div id="header">
                    <Header parentUpdateCount={updateCount}/>
                </div>

                <div id="body">
                    <Routes>
                        <Route exact path='/' element={<HomePage/>}/>
                        <Route path='/about-us' element={<AboutUs2/>}/>
                        <Route path='/about-us2' element={<AboutUs2/>}/>
                        <Route path='/blog' element={<Blog/>}/>
                        <Route path='/capabilities/:categoryLink'
                               element={<Capabilities count={count} parentUpdateCount={updateCount}/>}/>
                        <Route path='/contact-us' element={<ContactUs/>}/>
                        <Route path='/case-studies' element={<CaseStudies/>}/>
                        <Route path='/case-studies/:caseStudyHeading' element={<CaseStudyDescription/>}/>
                        {/* <Route path='/blog/:blogHeading' element={<BlogDescription/>}/> */}
                        <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                        <Route path='/blogs' element={<BlogDescription/>}/>
                        <Route path='*' element={<Navigate to="/"/>}/>
                    </Routes>
                </div>

                <div id="footer">
                    <Footer/>
                </div>

            </ScrollToTop>
        </Router>
    );
}

export default App;
