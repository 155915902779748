import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import WhiteArrow from "../assets/images/white-arrow.svg";
import Arrow from "../assets/images/arrow.svg";

import '../assets/css/Blog.css';

import TalkToUs from "../components/TalkToUs";
import Loading from "../components/Loading";

import {loadBlogs, loadLimitedBlogs} from "../common/Common";

function Blog() {
    const [blogs, setBlogs] = useState([]),
        [loadingContent, setLoadingContent] = useState(true),
        navigate = useNavigate();

    useEffect(() => {
        loadLimitedBlogs(10).then(data => {
            setBlogs(data);
            setLoadingContent(false);
        }).catch(() => null);
    }, []);

    const blogBody = () => {
        let body = [];
        if (blogs) {
            blogs.forEach((blog, index) => {
                if (index !== 0) {
                    body.push(
                        <div className="col" key={"blog" + index} style={{cursor: "pointer", marginBottom: "2%"}}
                             onClick={() => navigate("/blog/" + blog.heading)}>
                            <div className="card h-100 bg-body"
                                 style={{borderRadius: 12, border: "none", padding: "5%"}}>
                                <img src={blog.header_image_url} alt={blog.heading}
                                     style={{
                                         width: "100%",
                                         height: "100%",
                                         maxHeight: "30vh",
                                         borderRadius: 8,
                                         objectFit: "cover"
                                     }}/>
                                <div className="card-body p-0" style={{marginTop: "5%"}}>
                                    <h5 className="card-title home-insights-cart-title">{blog.heading}</h5>
                                </div>
                                <div className="card-body p-0" style={{marginTop: "5%"}}>
                                    <div className="d-flex flex-row">
                                        <div style={{marginRight: "5%"}}>
                                            <img src={blog.author.image_url} alt={blog.author.name}
                                                 style={{width: 48, height: 48, borderRadius: "50%"}}/>
                                        </div>
                                        <div>
                                            <h6 className="home-luke">{blog.author.name}</h6>
                                            <p className="home-date">{blog.published_on}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            });
        }
        return body;
    }

    if (!loadingContent) {
        return (
            <div>
                <div className="panel-page-format">
                    <div className="about-us-header-container"
                         style={{backgroundColor: "#FAFBFC", paddingTop: "25vh", paddingBottom: "10vh"}}>
                        <div className="container">
                            <div>
                                <h1 className="about-title mb-4">Latest News & Updates</h1>
                                <p className="about-subtitle">
                                    Find out the latest news and get a glimpse of our team's expertise and thoughts in
                                    and around the digital industry.
                                </p>
                            </div>
                        </div>
                    </div>

                    {blogs.length > 0 &&
                    <div className="container latest-blog-container" style={{marginTop: "10%"}}>
                        <div className="d-flex flex-row gap-5" style={{alignItems: "center", cursor: "pointer"}}
                             onClick={() => navigate("/blog/" + blogs[0].heading)}>
                            <div className="d-block-inline w-50">
                                <img src={blogs[0].header_image_url} className="img-fluid"
                                     alt={blogs[0].heading}
                                     style={{
                                         width: "100%",
                                         height: "100%",
                                         maxHeight: "40vh",
                                         borderRadius: 8,
                                         objectFit: "cover"
                                     }}/>
                            </div>
                            <div className="d-block-inline w-50" style={{paddingLeft: "5%"}}>
                                <div className="card-body p-0">
                                    <h5 className="card-title blog-insights-cart-title">{blogs[0].heading}</h5>
                                </div>
                                <div style={{marginTop: "5%"}}>
                                    <div className="d-flex flex-row">
                                        <div style={{marginRight: "5%"}}>
                                            <img src={blogs[0].author.image_url} alt={blogs[0].author.name}
                                                 style={{width: 48, height: 48, borderRadius: "50%"}}/>
                                        </div>
                                        <div>
                                            <h6 className="home-luke">{blogs[0].author.name}</h6>
                                            <p className="home-date">{blogs[0].published_on}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <hr className="container mt-5" style={{color: "#EAECF0"}}/>

                    <div style={{marginTop: "10vh"}}>
                        <div className="container">
                            <h4 className="blog-poststitle">Recent Blog Posts</h4>
                        </div>

                        {/**start posts */}
                        <div style={{marginTop: "5vh"}}>
                            <div className="container">
                                <div className="row row-cols-3 g-4 blog-list-container">
                                    {blogBody()}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/**end posts */}

                    <div style={{marginTop: "15vh"}}>
                        <div className="d-flex justify-content-center" style={{alignItems: "center"}}>
                            <div className="col container home-hr">
                                <hr/>
                            </div>
                            <button type="button" className="btn btn-lg red-button load-more-button"
                                    onClick={() => {
                                        setLoadingContent(true);
                                        loadBlogs().then(data => {
                                            setBlogs(data);
                                            setLoadingContent(false);
                                        }).catch(() => null);
                                    }}>
                                Load More Articles...
                                <img src={WhiteArrow} alt="" className="ms-1"/>
                                <img src={Arrow} alt="" className="ms-1 hover-icon"/>
                            </button>
                            <div className="col container home-hr">
                                <hr/>
                            </div>
                        </div>
                    </div>
                    <TalkToUs/>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default Blog
