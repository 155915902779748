import React, {useEffect, useState} from 'react';
import EditorJS from '@editorjs/editorjs';
import {getEditorJSTools} from "../components/editor-constants";

import {loadBlogFromHeading} from "../common/Common";

import Loading from "../components/Loading";
import TalkToUs from "../components/TalkToUs";

import FBLogo from "../assets/images/facebook-grey.svg";
import TwitterLogo from "../assets/images/twitter-grey.svg";
import LInLogo from "../assets/images/linkedin-grey.svg";
import CopyIcon from "../assets/images/copy-grey.svg";

let editor = undefined;

function BlogDescription() {
    const [blogHeading, setBlogHeading] = useState(decodeURI(window.location.href.split('/').at(-1))),
        [blog, setBlog] = useState(null),
        [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        const tempBlogHeading = decodeURI(window.location.href.split('/').at(-1));
        setBlogHeading(tempBlogHeading);
        editor = undefined;
        loadBlogFromHeading(tempBlogHeading).then(data => {
            setBlog(data);
            setLoadingContent(false);
        }).catch(() => null);
    }, []);

    if (!loadingContent && blog) {
        if (!editor) {
            editor = new EditorJS({
                holder: 'editor',
                tools: getEditorJSTools(null),
                readOnly: true,
                data: JSON.parse(blog.content),
            });
        }
        return (
            <div>
                <div className="panel-page-format case-study-description-container">
                    <div className="capabilities-header-container about-us-header-container"
                         style={{backgroundColor: "#FAFBFC", paddingTop: "25vh", paddingBottom: "10%"}}>
                        <div className="container blog-header-container">
                            <div>
                                <h1 className="about-title mb-4 text-left">
                                    {blog.heading}
                                </h1>
                                <div className="d-flex blog-header-detail-container"
                                     style={{margin: "5% 0", alignItems: "center"}}>
                                    <div style={{width: "70%"}}>
                                        <div className="d-flex flex-row">
                                            <div style={{marginRight: "2%"}}>
                                                <img src={blog.author.image_url} alt={blog.author.name}
                                                     style={{width: 48, height: 48, borderRadius: "50%"}}/>
                                            </div>
                                            <div>
                                                <h6 className="home-luke">{blog.author.name}</h6>
                                                <p className="home-date">{blog.author.designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end"
                                         style={{width: "30%", alignItems: "center"}}>
                                        <div style={{marginRight: "2%"}}>
                                            <div className="nav-link"
                                                 style={{
                                                     color: "#344054",
                                                     fontWeight: 500,
                                                     fontSize: 14,
                                                     lineHeight: "27px",
                                                     border: "1px solid #D0D5DD",
                                                     borderRadius: 8,
                                                     width: "100%",
                                                     cursor: "pointer"
                                                 }} onClick={() => {
                                                navigator.clipboard.writeText(window.location.href)
                                            }}>
                                                <img src={CopyIcon} alt="copy-icon" style={{marginRight: "15px"}}
                                                     className='footer-social-media-icons'/>
                                                Copy Link
                                            </div>
                                        </div>
                                        <div>
                                            <a className="nav-link"
                                               href={"https://facebook.com/sharer/sharer.php?u=" + window.location.href}
                                               target="_blank" style={{
                                                border: "1px solid #D0D5DD",
                                                borderRadius: 8,
                                                marginRight: "10%"
                                            }}>
                                                <img src={FBLogo} alt="facebook-icon"
                                                     className='footer-social-media-icons'/>
                                            </a>
                                        </div>
                                        <div>
                                            <a className="nav-link"
                                               href={"https://twitter.com/intent/tweet/?text=" + blog.main_heading + "&amp;url=" + window.location.href}
                                               target="_blank" style={{
                                                border: "1px solid #D0D5DD",
                                                borderRadius: 8,
                                                marginRight: "10%"
                                            }}>
                                                <img src={TwitterLogo} alt="twitter-icon"
                                                     className='footer-social-media-icons'/>
                                            </a>
                                        </div>
                                        <div>
                                            <a className="nav-link"
                                               href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + window.location.href + "&amp;title=" + blog.main_heading + "&amp;summary=" + blog.main_heading + "&amp;source=" + window.location.href}
                                               target="_blank" style={{
                                                border: "1px solid #D0D5DD",
                                                borderRadius: 8
                                            }}>
                                                <img src={LInLogo} alt="linkedin-icon"
                                                     className='footer-social-media-icons'/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{color: "#EAECF0", marginBottom: "5%"}}/>
                            </div>
                            <img src={blog.header_image_url} alt={blog.heading}
                                 style={{maxHeight: "45vh", marginBottom: "5%", borderRadius: 8}}
                                 className="w-100 float-end case-study-image blog-image"/>
                        </div>
                    </div>
                    <div className="container" style={{marginTop: "10vh"}}>
                        <div id="editor"/>
                    </div>
                </div>
                <TalkToUs/>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default BlogDescription;
