import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import "../assets/css/Footer.css";

import {loadCategories} from "../common/Common";

function Footer() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        loadCategories().then(data => {
            setCategories(data);
        }).catch(() => null);
    }, []);

    const categoryBody = () => {
        let body = [];
        if (categories) {
            categories.forEach((category, index) => {
                body.push(
                    <div key={"footerCategory" + index}>
                        <Link className="nav-link" to={"/capabilities/" + category.link}>
                            <h6 className="sub-link">{category.name}</h6>
                        </Link>
                    </div>
                );
            });
        }
        return body;
    }

    return (
        <footer>
            <div className="container">
                <div className="row row-cols-6 footer-row">
                    <div className="col">
                        <div className="d-flex ftmobile flex-column">
                            <div>
                                <h6 className="link">Company</h6>
                            </div>
                            <div>
                                <Link className="nav-link" to="/about-us">
                                    <h6 className="sub-link">About Us</h6>
                                </Link>
                            </div>
                            <div>
                                <Link className="nav-link" to="/about-us/#careers">
                                    <h6 className="sub-link">Careers</h6>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="d-flex ftmobile flex-column">
                            <div>
                                <h6 className="link">Capabilities</h6>
                            </div>
                            {categoryBody()}
                        </div>
                    </div>

                    <div className="col">
                        <div className="d-flex ftmobile flex-column">
                            <div>
                                <h6 className="link">Resources</h6>
                            </div>
                            <div>
                                <Link className="nav-link" to="https://yellawt.com/blogs/">
                                    <h6 className="sub-link">Blogs</h6>
                                </Link>
                            </div>
                            <div>
                                <Link className="nav-link" to="/case-studies">
                                    <h6 className="sub-link">Case Studies</h6>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="d-flex ftmobile flex-column">
                            <div>
                                <h6 className="link">Contact</h6>
                            </div>
                            <div>
                                <Link className="nav-link" to="/contact-us">
                                    <h6 className="sub-link">Contact Us</h6>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="d-flex ftmobile flex-column">
                            <div>
                                <h6 className="link">Legal</h6>
                            </div>
                            <div>
                                <Link className="nav-link" to="/privacy-policy">
                                    <h6 className="sub-link">Privacy Policy</h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{color: "#6B7580"}} className="hr-spc-footer"/>
                <div className="d-flex flex-row footer-row-end footer-rights">
                    <div className="" style={{color: "#FFFFFF"}}>
                        <p className="footer-copyrights footer-end text-start">
                            © {new Date().getFullYear()} All Rights Reserved
                        </p>
                    </div>

                    <div className="ms-auto">
                        <div className="d-flex flex-row justify-content-end bd-highlight footer-row-end">
                            <div style={{width: "100%"}}>
                                <a className="nav-link sub-link footer-media" href="mailto:hello@yellawt.com">
                                    hello@yellawt.com
                                </a>
                            </div>
                            <div>
                                <a className="nav-link sub-link footer-media"
                                   href="https://www.facebook.com/yellawtlk/"
                                   target="_blank">
                                    Facebook
                                </a>
                            </div>
                            <div>
                                <a className="nav-link sub-link footer-media"
                                   href="https://www.linkedin.com/company/yellawt/?originalSubdomain=lk"
                                   target="_blank">
                                    LinkedIn
                                </a>
                            </div>
                            <div>
                                <a className="nav-link sub-link footer-media"
                                   href="https://www.instagram.com/yellawt/?hl=en"
                                   target="_blank">
                                    Instagram
                                </a>
                            </div>
                            <div>
                                <a className="nav-link sub-link footer-media"
                                   href="https://www.tiktok.com/@yellawt"
                                   target="_blank">
                                    TikTok
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
