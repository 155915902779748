import React, {useEffect, useState} from 'react';
import EditorJS from '@editorjs/editorjs';
import {getEditorJSTools} from "../components/editor-constants";

import {loadCaseStudyFromHeading} from "../common/Common";

import Loading from "../components/Loading";
import TalkToUs from "../components/TalkToUs";

let editor = undefined;

function CaseStudyDescription() {
    const [caseStudyHeading, setCaseStudyHeading] = useState(decodeURI(window.location.href.split('/').at(-1))),
        [caseStudy, setCaseStudy] = useState(null),
        [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        const tempCaseStudyHeading = decodeURI(window.location.href.split('/').at(-1));
        setCaseStudyHeading(tempCaseStudyHeading);
        editor = undefined;
        loadCaseStudyFromHeading(tempCaseStudyHeading).then(data => {
            setCaseStudy(data);
            setLoadingContent(false);
        }).catch(() => null);
    }, []);

    if (!loadingContent && caseStudy) {
        if (!editor) {
            editor = new EditorJS({
                holder: 'editor',
                tools: getEditorJSTools(null),
                readOnly: true,
                data: JSON.parse(caseStudy.content),
            });
        }
        return (
            <div>
                <div className="panel-page-format case-study-description-container">
                    <div className="capabilities-header-container about-us-header-container"
                         style={{backgroundColor: "#FAFBFC", paddingTop: "25vh", paddingBottom: "10%"}}>
                        <div className="container case-study-detail-container">
                            <div>
                                {/* <h6 className="case-study-category"
                                    style={{
                                        color: caseStudy.category.color,
                                        backgroundColor: caseStudy.category.color + "10",
                                        border: caseStudy.category.color,
                                        marginBottom: "2%"
                                    }}>
                                    {caseStudy.category.name}
                                </h6> */}
                                <h1 className="about-title dspl-non mb-4 text-left spc-case-txt-hrd">
                                    {caseStudy.heading}
                                </h1>
                                <p className="case-sub text-left w-100 spc-case-txt-sub">
                                    {caseStudy.description}
                                </p>
                            </div>
                            <img src={caseStudy.header_image_url} alt={caseStudy.heading}
                                 style={{marginBottom: "5%", borderRadius: 6}}
                                 className="w-100 float-end case-study-image"/>
                        </div>
                    </div>
                    <div className="container" style={{marginTop: "10vh"}}>
                        <div id="editor"/>
                    </div>
                </div>
                <TalkToUs/>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default CaseStudyDescription;
