import React, {useEffect, useState} from 'react';
import {NavLink,useLocation } from 'react-router-dom';
import styled from "styled-components";

import '../assets/css/Header.css';

import Logo from '../assets/images/yellawt-white.svg';
import Logo2 from '../assets/images/yellawt-orange.svg';
import WhiteArrow from '../assets/images/white-arrow.svg';
import Arrow from '../assets/images/arrow.svg';

import {loadCategories} from "../common/Common";

function Header({parentUpdateCount}) {
    const [categories, setCategories] = useState([]),
        [windowWidth, setWindowWidth] = useState(0),
        [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        loadCategories().then(data => {
            setCategories(data);
        }).catch(() => null);
    }, []);

    const resizeWindow = () => {
        setWindowWidth(window.outerWidth);
    };
    
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const categoryBody = () => {
        let body = [];
        if (categories) {
            categories.forEach((category, index) => {
                body.push(
                    <>
                        <li key={"headerCategory" + index}>
                            <NavLink className="nav-link header-link new-link-spc" to={"/capabilities/" + category.link}
                                    activeClassName="is-active"
                                    data-toggle={windowWidth <= 991 ? "collapse" : ""}
                                    data-target="#navbarSupportedContent"
                                    onClick={() => {
                                        document.getElementsByClassName('dropdown-toggle')[0].setAttribute('aria-expanded', 'false');
                                        document.getElementsByClassName('dropdown-menu')[0].classList.remove('show');
                                        document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false');
                                        parentUpdateCount();
                                    }}>
                                {category.name}
                                <svg className="menu-dpr-arr" xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                                    <path d="M7.66913 2.77614L1.93141 8.51387L0.988602 7.57107L6.72633 1.83333H1.66915V0.5H9.00246V7.83333H7.66913V2.77614Z" fill="#16181D"/>
                                </svg>
                            </NavLink>
                        </li>
                    </>
                );
            });
        }
        return body;
    }
    const handleItemClick = () => {
        // Collapse the dropdown menu
        const dropdownToggle = document.querySelector('.dropdown-toggle');
        const dropdownMenu = document.querySelector('.dropdown-menu');
        const navbarToggler = document.querySelector('.navbar-toggler');

        if (dropdownToggle) {
            dropdownToggle.setAttribute('aria-expanded', 'false');
        }
        if (dropdownMenu) {
            dropdownMenu.classList.remove('show');
        }
        if (navbarToggler) {
            navbarToggler.setAttribute('aria-expanded', 'false');
        }

        // Collapse the navbar (if using Bootstrap)
        const navbarCollapse = document.getElementById('navbarSupportedContent');
        if (navbarCollapse) {
            navbarCollapse.classList.remove('show');
        }

        // Call the parent update function
        parentUpdateCount();
    };
    const megaMenuBody = () => {
        let columns = [[], [], []];
    
        if (categories) {
            categories.forEach((category, index) => {
                columns[index % 3].push(
                    <li key={"headerCategory" + index}>
                        <NavLink
                            className="nav-link menu-main-special"
                            to={"/capabilities/" + category.link}
                            activeClassName="is-active"
                            data-toggle={windowWidth <= 991 ? "collapse" : ""}
                            data-target="#navbarSupportedContent"
                            onClick={() => {
                                // Collapse the dropdown menu
                                const dropdownToggle = document.querySelector('.dropdown-toggle');
                                const dropdownMenu = document.querySelector('.dropdown-menu');
                                const navbarToggler = document.querySelector('.navbar-toggler');
    
                                if (dropdownToggle) {
                                    dropdownToggle.setAttribute('aria-expanded', 'false');
                                }
                                if (dropdownMenu) {
                                    dropdownMenu.classList.remove('show');
                                }
                                if (navbarToggler) {
                                    navbarToggler.setAttribute('aria-expanded', 'false');
                                }
                                
                                // Collapse the navbar (if using Bootstrap)
                                const navbarCollapse = document.getElementById('navbarSupportedContent');
                                if (navbarCollapse) {
                                    navbarCollapse.classList.remove('show');
                                }
    
                                // Call the parent update function
                                parentUpdateCount();
                                window.location.href = `/capabilities/${category.link}`;
                            }}
                        >
                            <Div2>
                                <Div3>
                                    <Column>
                                        <Div4>
                                            <Div5 style={{hoverBackgroundColor: category.color}} className='hovering-color menu-main-special'   onClick={handleItemClick}>
                                                <Div6>
                                                    <Div7 className="color-box-mega "  style={{ '--category-color': '#ffff' }}>
                                                        <img 
                                                            className='mega-image'
                                                            loading="lazy"
                                                            src={category.image_url}
                                                        />
                                                    </Div7>
                                                    <Div8 className="touch-name">
                                                        <Div9 style={{color: '#000'}} >{category.name}</Div9>
                                                        <Div10 className="toggler-mob-description">{category.description}</Div10>
                                                    </Div8>
                                                </Div6>
                                                {/* <Img2
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c9463ffd42b63635b3e9edb15af52668cfd386588e497363122a49934d9d495?apiKey=4cda531a3f714ff1ac04e891a48d63d0&"
                                                /> */}
                                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path id="Vector" d="M6.68053 2.27614L0.942806 8.01387L0 7.07107L5.73773 1.33333H0.680546V0H8.01386V7.33333H6.68053V2.27614Z" fill="#000"/>
                                                </svg>
                                            </Div5>
                                        </Div4>
                                    </Column>
                                </Div3>
                            </Div2>
                        </NavLink>
                    </li>
                );
            });
        }
    
        return (
            <div className="mega-menu">
                <div className="row">
                    <div className="col">
                        <ul className="list-unstyled">
                            {columns[0]}
                        </ul>
                    </div>
                    <div className="col">
                        <ul className="list-unstyled">
                            {columns[1]}
                        </ul>
                    </div>
                    <div className="col">
                        <ul className="list-unstyled">
                            {columns[2]}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
    

    useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > window.innerHeight;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    }, []);
    
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        // Function to handle checkbox change
        const handleCheckboxChange = () => {
          setIsChecked(prevState => !prevState);
        };
    
        // Cleanup
        return () => {
          // Remove any cleanup if necessary
        };
      }, []);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const Div = styled.div`
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 36px;
  @media (max-width: 678px) {
    padding: 0 20px;
  }
`;

const Div2 = styled.div`
  @media (max-width: 678px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 678px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 0px;
  @media (max-width: 678px) {
    width: 100%;
  }
`;

const Div4 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 678px) {
    margin-top: 12px;
  }
`;

const Div5 = styled.div`
  border-radius: 6px;
  background-color: #000;
  display: flex;
  gap: 9px;
  padding: 18px;
`;

const Div6 = styled.div`
  display: flex;
  gap: 19px;
`;

const Div7 = styled.div`
  align-items: center;
  border-radius: 6px;
  align-self: start;
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const Div8 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Div9 = styled.div`
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font: 700 18px SF Pro Display, sans-serif;
`;

const Div10 = styled.div`
  color: #6b7786;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 9px;
  font: 400 14px/20px SF Pro Display, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 8px;
  fill: #63c;
  align-self: start;
`;

useEffect(() => {
    const handleMenuClick = (event) => {
      event.preventDefault();  // Prevent the default link behavior
      const targetHref = event.currentTarget.href;
      sessionStorage.setItem('shouldHardRefresh', 'true');
      window.location.href = targetHref;
    };

    // Select the menu items with the class .menu-main-special
    const menuItems = document.querySelectorAll('.menu-main-special a');

    // Add event listeners to each menu item
    menuItems.forEach(item => {
      item.addEventListener('click', handleMenuClick);
    });

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      menuItems.forEach(item => {
        item.removeEventListener('click', handleMenuClick);
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const colorLine = document.querySelector('.color-line');
      const childElements = colorLine.children;

      if (window.scrollY > 0) {
        colorLine.style.height = '0';
        Array.from(childElements).forEach(child => {
          child.style.opacity = '0';
        });
      } else {
        // Determine the height based on the window width
        if (window.innerWidth <= 991) {
          colorLine.style.height = '12.5vw';
        } else {
          colorLine.style.height = '2.5vw';
        }
        Array.from(childElements).forEach(child => {
          child.style.opacity = '1';
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return (
        <div>
            <header className={`fixed-top only-mob-spc navbar-header  ${isHomePage && scrolled ? 'scrolled' : 'scrolled'} ${!isHomePage ? 'non-home-page' : ''}`}  style={{ display: isLoading ? 'none' : 'block' }}
                                        disabled={isLoading}>
                <nav className="navbar navbar-expand-lg">
                    <div className="container" >
                        <NavLink className="navbar-brand menu-main-special " to="/" data-toggle={windowWidth <= 991 ? "collapse" : ""}
                                 data-target=".navbar-collapse.show"
                                 onClick={() => {
                                     document.getElementsByClassName('dropdown-toggle')[0].setAttribute('aria-expanded', 'false');
                                     document.getElementsByClassName('dropdown-menu')[0].classList.remove('show');
                                     document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')
                                     window.location.href = `/`;
                                 }}>
                            <img src={Logo2} alt="Logo" className='black-bg-logo'/>
                            <img src={Logo2} alt="Logo" className='white-bg-logo'/>
                        </NavLink>
                        {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            {/* <span class="navbar-toggler-icon"></span> */}
                            {/* <input type="checkbox" id="checkbox1" class="checkbox1 visuallyHidden navbar-toggler-icon"/>
                            <label for="checkbox1">
                                <div class="hamburger hamburger1">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                    <span class="bar bar4"></span>
                                </div>
                            </label>
                        </button>  */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <input type="checkbox" id="checkbox1" className="checkbox1 visuallyHidden navbar-toggler-icon" checked={isChecked} onChange={() => setIsChecked(prevState => !prevState)} />
                            <label htmlFor="checkbox1">
                                <div className={`hamburger hamburger1 ${isChecked ? 'is-active' : ''}`}>
                                <span className="bar bar1"></span>
                                <span className="bar bar2"></span>
                                <span className="bar bar3"></span>
                                <span className="bar bar4"></span>
                                </div>
                            </label>
                            </button>
                        
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mx-auto "/>
                            <ul className="navbar-nav mb-5 mb-lg-0 me-auto">
                                <li className="nav-item me-3">
                                    <NavLink className={`nav-link header-link menu-main-special ${location.pathname !== '/' ? 'non-home-page' : ''}`} to="/case-studies" activeClassName="is-active"
                                             data-toggle={windowWidth <= 991 ? "collapse" : ""}
                                             data-target="#navbarSupportedContent"
                                             onClick={() => {
                                                document.getElementsByClassName('dropdown-toggle')[0].setAttribute('aria-expanded', 'false');
                                                document.getElementsByClassName('dropdown-menu')[0].classList.remove('show');
                                                document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')
                                                window.location.href = `/case-studies`;
                                            }}>
                                        Work
                                    </NavLink>
                                </li>

                                <li className="nav-item me-3" style={{position: "relative"}}>
                                    <NavLink className={`nav-link dropdown-toggle header-link spc-tgl-hdr ${location.pathname !== '/' ? 'non-home-page' : ''}`} to="/resources"
                                             activeClassName="is-active"
                                             data-bs-toggle="dropdown" aria-expanded="false">
                                        Resources
                                    </NavLink>
                                    <ul className="dropdown-menu spc-drp-mnu-1">
                                        {/* <>
                                             <div className='spc-tag'>
                                                Resources
                                             </div>
                                        </> */}
                                       <li className='new-link-spc'>
                                            <NavLink className="nav-link header-link new-link-spc menu-main-special " to={"/resources/"}>
                                                Blogs
                                                <svg className="menu-dpr-arr" xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                                                    <path d="M7.66913 2.77614L1.93141 8.51387L0.988602 7.57107L6.72633 1.83333H1.66915V0.5H9.00246V7.83333H7.66913V2.77614Z" fill="#16181D"/>
                                                </svg>
                                            </NavLink>
                                       </li>
                                    </ul>
                                </li>
                                
                                <li className="nav-item me-3">
                                    <NavLink className={`nav-link header-link menu-main-special ${location.pathname !== '/' ? 'non-home-page' : ''}`} to="/about-us"
                                             activeClassName="is-active"
                                             data-toggle={windowWidth <= 991 ? "collapse" : ""}
                                             data-target="#navbarSupportedContent"
                                             onClick={() => {
                                                 document.getElementsByClassName('dropdown-toggle')[0].setAttribute('aria-expanded', 'false');
                                                 document.getElementsByClassName('dropdown-menu')[0].classList.remove('show');
                                                 document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')
                                                 window.location.href = `/about-us`;
                                             }}>
                                        Agency
                                    </NavLink>
                                </li>

                                <li className="nav-item me-3" style={{position: ""}}>
                                    <NavLink className={`nav-link dropdown-toggle header-link spc-tgl-hdr ${location.pathname !== '/' ? 'non-home-page' : ''}`} to="/services"
                                             activeClassName="is-active"
                                             data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </NavLink>
                                    <ul className="dropdown-menu spc-drp-mnu-1 mega-box">
                                        {/* <>
                                             <div className='spc-tag'>
                                                Services
                                             </div>
                                        </> */}
                                        {/* {categoryBody()} */}
                                        {/* <div className='mega-menu-hdr bg-primary'>
                                            testing mega menu
                                        </div> */}

                                            <Div className='mega-menu-hdr'>
                                                {megaMenuBody()}
                                            </Div>

                                        {/* <div class="mega-box">
                                        <div class="mega-content">
                                            <div class="row">
                                            <img src="" alt=""/>
                                            </div>
                                            <div class="row">
                                            <header>Design Services</header>
                                            <ul class="mega-links">
                                                <li><a href="#">Graphics</a></li>
                                                <li><a href="#">Vectors</a></li>
                                                <li><a href="#">Business cards</a></li>
                                                <li><a href="#">Custom logo</a></li>
                                            </ul>
                                            </div>
                                            <div class="row">
                                            <header>Email Services</header>
                                            <ul class="mega-links">
                                                <li><a href="#">Personal Email</a></li>
                                                <li><a href="#">Business Email</a></li>
                                                <li><a href="#">Mobile Email</a></li>
                                                <li><a href="#">Web Marketing</a></li>
                                            </ul>
                                            </div>
                                            <div class="row">
                                            <header>Security services</header>
                                            <ul class="mega-links">
                                                <li><a href="#">Site Seal</a></li>
                                                <li><a href="#">VPS Hosting</a></li>
                                                <li><a href="#">Privacy Seal</a></li>
                                                <li><a href="#">Website design</a></li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div> */}
                                    </ul>
                                </li>        
                                
                                <li className="nav-item me-5">
                                    <NavLink className={`nav-link header-link menu-main-special ${location.pathname !== '/' ? 'non-home-page' : ''}`}  to="/contact-us" activeClassName="is-active"
                                             data-toggle={windowWidth <= 991 ? "collapse" : ""}
                                             data-target="#navbarSupportedContent"
                                             onClick={() => {
                                                 document.getElementsByClassName('dropdown-toggle')[0].setAttribute('aria-expanded', 'false');
                                                 document.getElementsByClassName('dropdown-menu')[0].classList.remove('show');
                                                 document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')
                                                 window.location.href = `/contact-us`;
                                             }}>
                                        Contact
                                    </NavLink>
                                </li>
                            </ul>
                            <NavLink className="nav-link header-link cto-button" to="https://calendly.com/yellawt" target='_blank'
                                     activeClassName="is-active"
                                     data-toggle={windowWidth <= 991 ? "collapse" : ""}
                                     data-target="#navbarSupportedContent"
                                     onClick={() => {
                                         document.getElementsByClassName('dropdown-toggle')[0].setAttribute('aria-expanded', 'false');
                                         document.getElementsByClassName('dropdown-menu')[0].classList.remove('show');
                                         document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')
                                     }}>
                                <button type="button"
                                        className="btn btn-lg red-gradient-button header-titlebtn spc-btn-cto-hdr load-disable menu-main-special"
                                       
                                >

                                    Get in Touch
                                    {/* <img src={WhiteArrow} alt="" className="ms-1"/>
                                    <img src={Arrow} alt="" className="ms-1 hover-icon"
                                         style={{width: "17px", height: "17px"}}/> */}
                                </button>
                            </NavLink> 
                        </div>
                    </div>
                </nav>
                <style jsx>{`
        .navbar-header {
            transition: background-color 0.3s ease;
        }
        .navbar-header.scrolled {
            background-color: #fff;
        }
        .navbar-header.non-home-page {
            background-color: #fff;
        }
        .header-link.non-home-page {
            color: #000; 
        }
      `}</style>
                <div className='color-line' style={{background: '#63C'}}>
                    <a href="https://rooster.jobs/company/1131" className='spc-02-txt'>Supercharge your career with Yellawt. Apply Now!</a>
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                        <path d="M6.68053 2.7693L0.942806 8.50703L0 7.56423L5.73773 1.8265H0.680546V0.493164H8.01386V7.8265H6.68053V2.7693Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </header>
        </div>
    );
}

export default Header;