import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Carousel from "react-multi-carousel";

import Title from "../assets/images/aboutheader.jpg";
import Icon1 from "../assets/images/Icon4.svg";
import Icon2 from "../assets/images/Icon6.svg";
import Icon3 from "../assets/images/icon5.svg";
import Icon4 from "../assets/images/Icon7.svg";
import Icon5 from "../assets/images/Icon8.svg";
import Icon6 from "../assets/images/Icon9.svg";

import "../assets/css/AboutUs.css";

import TalkToUs from "../components/TalkToUs";
import Loading from "../components/Loading";

import {loadEmployeeTestimonialsLimited} from "../common/Common";

function AboutUs() {
    const [testimonials, setTestimonials] = useState([]),
        [selectedTestimonial, setSelectedTestimonial] = useState(null),
        [loadingTestimonialContent, setLoadingTestimonialContent] = useState(true);

    const responsivea = {
        largeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            dots: true,
            items: 1
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1
        },
        tablet: {
            breakpoint: {max: 1024, min: 768},
            items: 1
        },
        mobile: {
            breakpoint: {max: 768, min: 464},
            items: 1
        },
        smallMobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    useEffect(() => {
        loadEmployeeTestimonialsLimited(3).then(data => {
            setTestimonials(data);
            if (data.length > 0) {
                setSelectedTestimonial(data[0]);
            }
            setLoadingTestimonialContent(false);
        }).catch(() => null);
        const careers = decodeURI(window.location.href.split('/').at(-1));
        if (careers === '#careers') {
            const observer = new MutationObserver((mutations, obs) => {
                let careersDiv = document.getElementById("careers");
                if (careersDiv) {
                    careersDiv.scrollIntoView({behavior: 'smooth', block: 'start'});
                    obs.disconnect();
                }
            });
            observer.observe(document, {childList: true, subtree: true});
        }
    }, []);

    const testimonialSlides = testimonials.map((testimonial, index) => (
        <div key={`testimonial-${index}`}>
            <p className="new-testimo-mg">“ {testimonial.message} ”</p>
            <hr style={{background:'#fff'}} className='hr-new'/>
            <div className='flx-users-testimo'>
            <div className="user-img">
                <img
                  src={testimonial.image_url}
                  alt={testimonial.name}
                  className="new-testimo-img"
                />
            </div>
                <div>
                    <h6 className="new-testimo-name" >
                        {testimonial.name}
                    </h6>
                    <p className="new-testimo-desig">{testimonial.designation}</p>
                </div>
            </div>
        </div>
    ));

    const testimonialBody = () => {
        let body = [];
        if (testimonials) {
            testimonials.forEach((testimonial, index) => {
                body.push(
                    <div
                        className={selectedTestimonial.id === testimonial.id ? "home-border-clients selected-home-border-clients" : "home-border-clients"}
                        key={"testimonial" + index}
                        onClick={() => setSelectedTestimonial(testimonial)}>
                        <div className="d-flex flex-row">
                            <div>
                                <img src={testimonial.image_url} alt={testimonial.name}
                                     className="me-3 client-testimonial-image"/>
                            </div>
                            <div>
                                <h6 className="home-name">{testimonial.name}</h6>
                                <p className="home-job" style={{color: "#818D99"}}>{testimonial.designation}</p>
                            </div>
                        </div>
                    </div>
                );
                if (selectedTestimonial.id === testimonial.id) {
                    body.push(
                        <div className="mobile-selected-testimonial" key={"selectedTestimonial"}>
                            <h5 className="home-clienttitle">
                                {selectedTestimonial.heading}
                            </h5>
                            <p style={{border: "4px solid #EE4834", width: "10%", borderRadius: 4}}/>
                            <p className="home-clientsubtitle" style={{color: "#818D99"}}>
                                {selectedTestimonial.message}
                            </p>
                        </div>
                    );
                }
            });
        }
        return body;
    }

    return (
        <div>
            <div className="panel-page-format">
                <div className="about-us-header-container" style={{paddingTop: "25vh"}}>
                    <div className="container">
                        <div>
                            <h1 className="about-title mb-4 spc-txt-hdn">From concepts to Reality</h1>
                            <p className="about-subtitle spc-txt-sub">
                                At our core, we are fueled by curiosity and draw inspiration
                                from exceptional design, cutting-edge technologies, and
                                revolutionary brands.
                            </p>
                        </div>
                    </div>

                    <div className="container">
                        <div>
                            <img src={Title} className="about-header-img" alt="..."/>
                        </div>
                    </div>
                    {/* <div id="carousel2" className="carousel slide mt-5" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carousel2" data-bs-slide-to="0"
                                    className="about-carouselbtn" aria-label="Slide 1"/>
                            <button type="button" data-bs-target="#carousel2" data-bs-slide-to="1"
                                    className="active about-carouselbtn" aria-current="true" aria-label="Slide 2"
                                    style={{backgroundColor: "#FCB503"}}/>
                            <button type="button" data-bs-target="#carousel2" data-bs-slide-to="2"
                                    className="about-carouselbtn" aria-label="Slide 3"/>
                        </div>
                        <div className="carousel-inner" style={{textAlign: "center"}}>
                            <div className="carousel-item active">
                                <img src={Title} className=" " alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={Title} className=" " alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={Title} className=" " alt="..."/>
                            </div>
                        </div>
                    </div> */}
                </div>

                {/** */}
                <div className="container" style={{marginTop: "10vh"}}>
                    <div>
                        <h2 className="about-separate">What separates us from the rest?</h2>
                        <p className="about-subseparate">
                            From our customer-centric philosophy to our tech-driven approach, we are committed to
                            providing our clients with innovative, effective solutions that drive results.
                        </p>
                    </div>
                    <div className="row row-cols-3 about-separate-us-data-container" style={{marginTop: "10vh"}}>
                        <div className="col">
                            <img
                                src={Icon1}
                                className="card-img-top mx-auto d-block"
                                alt="..."
                                style={{width: "48px", height: "48px"}}
                            />
                            <div className="card-body">
                                <h5 className="about-card-title">Creative Strategies</h5>
                                <p className="about-card-text text-center">
                                    Our experts specialize in developing highly creative strategies to help businesses
                                    stand out & strongly resonate with their target audience.
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <img
                                src={Icon2}
                                className="card-img-top mx-auto d-block"
                                alt="..."
                                style={{width: "48px", height: "48px"}}
                            />
                            <div className="card-body">
                                <h5 className="about-card-title">Customer Focused</h5>
                                <p className="about-card-text text-center">
                                    We build strong relationships with our clients and work closely with them to
                                    understand their needs and ensure an exceptional customer experience..
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <img
                                src={Icon3}
                                className="card-img-top mx-auto d-block"
                                alt="..."
                                style={{width: "48px", height: "48px"}}
                            />
                            <div className="card-body">
                                <h5 className="about-card-title">Cutting-Edge Solutions</h5>
                                <p className="about-card-text text-center">
                                    We stay well ahead of the latest trends and technologies in the digital space to
                                    create unique and effective solutions for our clients
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <img
                                src={Icon4}
                                className="card-img-top mx-auto d-block"
                                alt="..."
                                style={{width: "48px", height: "48px"}}
                            />
                            <div className="card-body">
                                <h5 className="about-card-title">Result-Oriented</h5>
                                <p className="about-card-text text-center">
                                    We leverage digital technologies to drive traffic and boost sales. We then use
                                    data-driven strategies to measure success and achieve measurable results.
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <img
                                src={Icon5}
                                className="card-img-top mx-auto d-block"
                                alt="..."
                                style={{width: "48px", height: "48px"}}
                            />
                            <div className="card-body">
                                <h5 className="about-card-title">Strategic Partnerships</h5>
                                <p className="about-card-text text-center">
                                    Our belief in the power of collaboration leads us to work closely with our clients
                                    to form strategic partnerships with the best possible outcomes.
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <img
                                src={Icon6}
                                className="card-img-top mx-auto d-block"
                                alt="..."
                                style={{width: "48px", height: "48px"}}
                            />
                            <div className="card-body">
                                <h5 className="about-card-title">End To End Solutions</h5>
                                <p className="about-card-text text-center">
                                    Our aim is to provide our clients with a holistic approach to their digital needs
                                    and the flexibility to choose individual services based on their goals.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/** */}

                <div className='testimonials-new-sec mt-5' >
                    <div className="container">
                        <h2 className="employee-msg pb-5">
                            Hear from our Team
                        </h2>
                        {testimonials && testimonials.length > 0 ? (
                            <Carousel responsive={responsivea} infinite={true} dots={true} autoPlay={true} autoPlaySpeed={10000}
                                arrows={true}  showIndicators={false}   showStatus={true} showDots >
                                    {testimonialSlides}
                            </Carousel>
                        ) : (
                        <p className='no-testimo-avl'> No testimonials available in portal.</p>
                        )}
                    </div>
                </div>
                {/** */}

                <div className="container" style={{marginTop: "10%"}} id="careers">
                    <h3 className="about-careertitle">
                        Supercharge your career with Yellawt
                    </h3>
                    <p className="text-start about-careersubtitle">
                        Join our team of digital innovators! If you're passionate and
                        experienced in your field, and eager to grow your career, we want
                        you! Check out our job openings and apply today!
                    </p>
                    <hr style={{color: "#EAECF0", height: "1px"}}/>

                    <div id="rooster"/>
                </div>

                {/** */}
                {/* <TalkToUs/> */}
            </div>
            <Helmet>
                <script defer={true}>{`
                            var rooster = new Rooster('#rooster', 1131);
                            rooster.setup();
                            setTimeout(function(){ $(".rooster-job + .rooster-job").before($("<div class='rooster-department-header' style='margin-bottom: 0'/>")); }, 1000);
                        `}</script>
            </Helmet>
        </div>
    );
}

export default AboutUs;
