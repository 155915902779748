import React from 'react';
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";

import Frame from "../assets/images/Frame.png";
import WhiteArrow from "../assets/images/white-arrow.svg";
import Arrow from "../assets/images/arrow.svg";
import pcto from "../assets/images/Ellipse 919.svg";

import '../assets/css/TalkToUs.css';

function TalkToUs() {
    const navigate = useNavigate();

    const navigateToContacts = () => {
        navigate('/contact-us');
    };

    return (
        <div className='container'>
            <div className='cto-new'>
                <span className='cto-new-header'>Unlock your potential: <br/>Connect with our experts today!</span>
                <span className='cto-new-para'>Whether you aim for growth, innovation, or transformation, we're committed to shaping a brighter future for your project. Let's join forces and propel your success story forward!
                </span>

                <div className='cto-flex'>
                    <div className='talk-person'>
                        <img src={pcto}/>
                        <div>
                            <span className='p-nme-ct'>Nabeel Marikar</span>
                            <span className='p-nme2-ct'>Head of Client Partnerships</span>
                        </div>
                    </div>
                    <Link className="nav-link header-link cto-button-new-sp" to="https://calendly.com/yellawt" target='_blank'>
                        <button type="button" className="cto-new-spc">
                        Let’s Talk
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
                            <path d="M20.7071 8.20711C21.0976 7.81658 21.0976 7.18342 20.7071 6.7929L14.3431 0.428933C13.9526 0.038409 13.3195 0.038409 12.9289 0.428933C12.5384 0.819458 12.5384 1.45262 12.9289 1.84315L18.5858 7.5L12.9289 13.1569C12.5384 13.5474 12.5384 14.1805 12.9289 14.5711C13.3195 14.9616 13.9526 14.9616 14.3431 14.5711L20.7071 8.20711ZM-8.74228e-08 8.5L20 8.5L20 6.5L8.74228e-08 6.5L-8.74228e-08 8.5Z" fill="#252C33"/>
                            </svg>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default TalkToUs;