import {getRequest} from "../routes/Routes";

export const loadServices = async () => {
    let services = [];
    await getRequest('/api/service').then(response => {
        if (response.status === 200) {
            services = response.data.services;
        } else {
            services = [];
        }
    });
    return services;
};

export const loadCategories = async () => {
    let categories = [];
    await getRequest('/api/category').then(response => {
        if (response.status === 200) {
            categories = response.data.categories;
        } else {
            categories = [];
        }
    });
    return categories;
};

export const loadAllCaseStudies = async () => {
    let caseStudies = [];
    await getRequest('/api/case-study/').then(response => {
        if (response.status === 200) {
            caseStudies = response.data.caseStudies;
        } else {
            caseStudies = null;
        }
    });
    return caseStudies;
};

export const loadCategoryCaseStudies = async (categoryId) => {
    let caseStudies = [];
    await getRequest('/api/case-study/category/' + categoryId).then(response => {
        if (response.status === 200) {
            caseStudies = response.data.caseStudies;
        } else {
            caseStudies = [];
        }
    });
    return caseStudies;
};

export const loadCaseStudyFromHeading = async (heading) => {
    let caseStudy = null;
    await getRequest('/api/case-study/heading/' + heading).then(response => {
        if (response.status === 200) {
            caseStudy = response.data.caseStudy;
        } else {
            caseStudy = null;
        }
    });
    return caseStudy;
};

export const loadCaseStudiesFromCategory = async (categoryId) => {
    let caseStudies = [];
    await getRequest('/api/case-study/category/' + categoryId).then(response => {
        if (response.status === 200) {
            caseStudies = response.data.caseStudies;
        } else {
            caseStudies = null;
        }
    });
    return caseStudies;
};

export const loadCategoryCaseStudiesLimited = async (categoryId, limit) => {
    let caseStudies = [];
    await getRequest('/api/case-study/category/' + categoryId + '/limit/' + limit).then(response => {
        if (response.status === 200) {
            caseStudies = response.data.caseStudies;
        } else {
            caseStudies = [];
        }
    });
    return caseStudies;
};

export const loadCaseStudiesLimited = async (limit) => {
    let caseStudies = [];
    await getRequest('/api/case-study/limit/' + limit).then(response => {
        if (response.status === 200) {
            caseStudies = response.data.caseStudies;
        } else {
            caseStudies = [];
        }
    });
    return caseStudies;
};

export const loadTestimonials = async () => {
    let testimonials = [];
    await getRequest('/api/testimonial/').then(response => {
        if (response.status === 200) {
            testimonials = response.data.testimonials;
        } else {
            testimonials = [];
        }
    });
    return testimonials;
};

export const loadTestimonialsLimited = async (limit) => {
    let testimonials = [];
    await getRequest('/api/testimonial/limit/' + limit).then(response => {
        if (response.status === 200) {
            testimonials = response.data.testimonials;
        } else {
            testimonials = [];
        }
    });
    return testimonials;
};

export const loadEmployeeTestimonialsLimited = async (limit) => {
    let testimonials = [];
    await getRequest('/api/testimonial/limit/' + limit).then(response => {
        if (response.status === 200) {
            testimonials = response.data.testimonials;
        } else {
            testimonials = [];
        }
    });
    return testimonials;
};

export const loadPartners = async () => {
    let partners = [];
    await getRequest('/api/partner').then(response => {
        if (response.status === 200) {
            partners = response.data.partners;
        } else {
            partners = [];
        }
    });
    return partners;
};

export const loadServiceFromCategory = async (categoryName) => {
    let data = null;
    await getRequest('/api/service/category/' + categoryName).then(response => {
        if (response.status === 200) {
            data = response.data;
        } else {
            data = null;
        }
    });
    return data;
};

export const loadCategoryBlogsLimited = async (categoryId, limit) => {
    let blogs = [];
    await getRequest('/api/blog/category/' + categoryId + '/limit/' + limit).then(response => {
        if (response.status === 200) {
            blogs = response.data.blogs;
        } else {
            blogs = [];
        }
    });
    return blogs;
};

export const loadBlog = async (blogMainHeading) => {
    let data = null;
    await getRequest('/api/blog/main-heading/' + blogMainHeading).then(response => {
        if (response.status === 200) {
            data = response.data;
        } else {
            data = null;
        }
    });
    return data;
};

export const loadLimitedBlogs = async (limit) => {
    let blogs = null;
    await getRequest('/api/blog/limit/' + limit).then(response => {
        if (response.status === 200) {
            blogs = response.data.blogs;
        } else {
            blogs = null;
        }
    });
    return blogs;
};

export const loadBlogs = async () => {
    let blogs = null;
    await getRequest('/api/blog/').then(response => {
        if (response.status === 200) {
            blogs = response.data.blogs;
        } else {
            blogs = null;
        }
    });
    return blogs;
};

export const loadBlogFromHeading = async (heading) => {
    let blog = null;
    await getRequest('/api/blog/heading/' + heading).then(response => {
        if (response.status === 200) {
            blog = response.data.blog;
        } else {
            blog = null;
        }
    });
    return blog;
};
