import React, {useState} from 'react';
import {Tooltip} from 'react-tooltip';

import '../assets/css/ContactUs.css';

import TalkToUs from "../components/TalkToUs";

import {uploadFile} from "../routes/Routes";

import Location from "../assets/images/location.svg";

function ContactUs() {
    const [loading, setLoading] = useState(false),
        [success, setSuccess] = useState({}),
        [error, setError] = useState({});

    const formSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData();
        data.append('name', event.target.name.value);
        data.append('phone', event.target.phone.value);
        data.append('email', event.target.email.value);
        data.append('message', event.target.message.value);
        let services = [];
        if (event.target.website_design.checked) {
            services.push(event.target.website_design.value);
        }
        if (event.target.ux_design.checked) {
            services.push(event.target.ux_design.value);
        }
        if (event.target.user_research.checked) {
            services.push(event.target.user_research.value);
        }
        if (event.target.content_creation.checked) {
            services.push(event.target.content_creation.value);
        }
        if (event.target.strategy.checked) {
            services.push(event.target.strategy.value);
        }
        if (event.target.other.checked) {
            services.push(event.target.other.value);
        }
        data.append('services', JSON.stringify(services));
        uploadFile(data, '/api/contact-us', null).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    setSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    return (
        <div>
            <div className="panel-page-format">
                {/* <div className="about-us-header-container"
                     style={{backgroundColor: "#FAFBFC", paddingTop: "25vh", paddingBottom: "10vh"}}>
                    <div className="container">
                        <div>
                            <h1 className="about-title mb-4">Engage. Connect. Explore</h1>
                            <p className="about-subtitle">
                                We're here to answer your questions and explore how we can collaborate to achieve your
                                goals. Reach out and let's start creating something extraordinary together!
                            </p>
                        </div>
                    </div>
                </div> */}

                <div className='c-spc-main'>
                    <div className="d-flex flex-row gap-5 contact-row container new-gap">
                        <div className='contact-lft-spc'>
                            <div className='text-box-spc'>
                                <span className='main-name-c'>
                                     Dominating over 14+ regions around the world
                                </span>
                                
                                <span className='c-para-new'>We're here to answer your questions and explore how we can collaborate to achieve your goals. Reach out and let's start creating something extraordinary together!</span>
                                <span className='sub-h-c-spc'>Reach out to our team for all your digital needs</span>
                                <div className='flex-row-spc-c'>
                                    <a href='mailto:hello@yellawt.com' className='fl-single-c'>
                                        <div className='round-c'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19.5 8C20.8807 8 22 6.88071 22 5.5C22 4.11929 20.8807 3 19.5 3C18.1193 3 17 4.11929 17 5.5C17 6.88071 18.1193 8 19.5 8Z" fill="#FA0C00"/>
                                                <path d="M19.5 8C20.8807 8 22 6.88071 22 5.5C22 4.11929 20.8807 3 19.5 3C18.1193 3 17 4.11929 17 5.5C17 6.88071 18.1193 8 19.5 8Z" fill="#FA0C00"/>
                                                <path d="M20.72 9.31C20.02 9.53 19.25 9.57 18.45 9.37C17.11 9.02 16.02 7.95 15.65 6.61C15.47 5.96 15.46 5.32 15.57 4.74C15.7 4.1 15.25 3.5 14.61 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V10.26C22 9.6 21.36 9.1 20.72 9.31ZM15.52 11.15L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L14.58 9.97C14.9 9.71 15.38 9.76 15.63 10.09C15.89 10.41 15.84 10.89 15.52 11.15Z" fill="#FA0C00"/>
                                            </svg>
                                            
                                        </div>
                                        hello@yellawt.com
                                    </a>
                                    {/* <a href='tel:0764272488' className='fl-single-c'>
                                        <div className='round-c'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                            <path d="M13.7629 8.66284C13.4437 8.66284 13.1913 8.40299 13.1913 8.09117C13.1913 7.81648 12.9166 7.24481 12.4563 6.74739C12.0034 6.26481 11.506 5.98269 11.0902 5.98269C10.771 5.98269 10.5186 5.72284 10.5186 5.41102C10.5186 5.0992 10.7784 4.83936 11.0902 4.83936C11.8326 4.83936 12.6122 5.24026 13.2952 5.96042C13.9337 6.63602 14.342 7.47496 14.342 8.08375C14.342 8.40299 14.0822 8.66284 13.7629 8.66284Z" fill="#FA0C00"/>
                                            <path d="M16.4435 8.66272C16.1242 8.66272 15.8718 8.40287 15.8718 8.09105C15.8718 5.45544 13.7262 3.31726 11.098 3.31726C10.7788 3.31726 10.5264 3.05741 10.5264 2.74559C10.5264 2.43378 10.7788 2.1665 11.0906 2.1665C14.3573 2.1665 17.0152 4.82438 17.0152 8.09105C17.0152 8.40287 16.7553 8.66272 16.4435 8.66272Z" fill="#FA0C00"/>
                                            <path d="M8.88496 11.7809L7.51147 13.1544C7.22192 13.4439 6.76162 13.4439 6.46465 13.1618C6.38299 13.0801 6.30132 13.0059 6.21965 12.9242C5.45496 12.1521 4.7645 11.3429 4.14829 10.4965C3.5395 9.65014 3.0495 8.80378 2.69314 7.96484C2.3442 7.11847 2.16602 6.30923 2.16602 5.53711C2.16602 5.03226 2.25511 4.54969 2.43329 4.10423C2.61147 3.65135 2.89359 3.23559 3.28708 2.86438C3.76223 2.39666 4.28192 2.1665 4.83132 2.1665C5.0392 2.1665 5.24708 2.21105 5.43268 2.30014C5.62571 2.38923 5.79647 2.52287 5.93011 2.7159L7.65253 5.14363C7.78617 5.32923 7.88268 5.49999 7.9495 5.66332C8.01632 5.81923 8.05344 5.97514 8.05344 6.1162C8.05344 6.29438 8.00147 6.47256 7.89753 6.64332C7.80102 6.81408 7.65995 6.99226 7.48177 7.17044L6.91753 7.75696C6.83586 7.83862 6.79874 7.93514 6.79874 8.05393C6.79874 8.11332 6.80617 8.16529 6.82102 8.22469C6.84329 8.28408 6.86556 8.32862 6.88041 8.37317C7.01405 8.61817 7.2442 8.93741 7.57086 9.32347C7.90495 9.70953 8.26132 10.103 8.64738 10.4965C8.72162 10.5707 8.80329 10.645 8.87753 10.7192C9.1745 11.0088 9.18192 11.4839 8.88496 11.7809Z" fill="#FA0C00"/>
                                            <path d="M16.9924 14.2905C16.9924 14.4983 16.9553 14.7137 16.881 14.9215C16.8587 14.9809 16.8365 15.0403 16.8068 15.0997C16.6806 15.367 16.5172 15.6194 16.3019 15.857C15.9381 16.2579 15.5372 16.5474 15.0844 16.733C15.0769 16.733 15.0695 16.7405 15.0621 16.7405C14.624 16.9187 14.1489 17.0152 13.6366 17.0152C12.8794 17.0152 12.0701 16.837 11.2163 16.4732C10.3625 16.1094 9.50874 15.6194 8.66238 15.0032C8.37284 14.7879 8.08329 14.5726 7.80859 14.3424L10.2363 11.9147C10.4442 12.0706 10.6298 12.1894 10.7857 12.2711C10.8228 12.2859 10.8674 12.3082 10.9194 12.3305C10.9787 12.3527 11.0381 12.3602 11.105 12.3602C11.2312 12.3602 11.3277 12.3156 11.4094 12.234L11.9736 11.6771C12.1592 11.4915 12.3374 11.3505 12.5081 11.2614C12.6789 11.1574 12.8497 11.1055 13.0353 11.1055C13.1763 11.1055 13.3248 11.1352 13.4881 11.202C13.6515 11.2688 13.8222 11.3653 14.0078 11.4915L16.4653 13.2362C16.6583 13.3699 16.7919 13.5258 16.8736 13.7114C16.9478 13.897 16.9924 14.0826 16.9924 14.2905Z" fill="#FA0C00"/>
                                            </svg>
                                            
                                        </div>
                                        hello@yellawt.com
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <form onSubmit={formSubmit} className="contact-container spc-c-f">
                            {/* <h4 className="text-start contact-formtitle mb-3">
                            Take the first step, let us know how we can help you bring your vision to life!
                            </h4>
                            <h6 className="contact-formsubtitle mb-3">
                                <a href="mailto:hello@yellawt.com">
                                    hello@yellawt.com
                                </a>
                            </h6> */}
                            {/*<h6 className="contact-formsubtitle">
                                <a href="tel:+94 764 272 488">
                                    +94 764 272 488
                                </a><br/>
                                <a href="tel:+94 762 247 514">
                                    +94 762 247 514
                                </a>
                            </h6>*/}

                            <div className="mb-4 mt-5">
                                <label htmlFor="FormControlInput1" className="form-label contact-lbl">Name</label>
                                <input type="text" name="name" className="form-control contact-input spc-inpt"
                                       id="FormControlInput1"
                                       placeholder="Your name" required/>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="FormControlInput1" className="form-label contact-lbl ">Email</label>
                                <input type="email" name="email" className="form-control contact-input spc-inpt"
                                       id="FormControlInput1"
                                       placeholder="you@company.com" required/>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="FormControlInput1" className="form-label contact-lbl">Phone
                                    number</label>
                                <input type="text" name="phone" className="form-control w-100 contact-input spc-inpt"
                                       id="FormControlInput1" placeholder="+1 (555) 000-0000"
                                       style={{borderLeft: "#FFFFFF"}} required/>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="FormControlTextarea1" className="form-label contact-lbl">How can we
                                    help?</label>
                                <textarea className="form-control contact-input" name="message"
                                          id="FormControlTextarea1"
                                          placeholder="Tell us a little about the project..." rows="3" required/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="FormControlInput1" className="form-label contact-lbl">Services</label>
                            </div>
                            <div className="d-flex flex-row contact-row-check">
                                <div className="mb-3">
                                    <div className="d-flex flex-row contact-row-check">
                                        <div>
                                            <div className="form-check form-check mb-3 me-5">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                       value="Website design" name="website_design"/>
                                                <label className="form-check-label contact-checklbl"
                                                       htmlFor="inlineCheckbox1">Website design</label>
                                            </div>
                                            <div className="form-check form-check mb-3 me-5">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                       value="UX design" name="ux_design"/>
                                                <label className="form-check-label contact-checklbl"
                                                       htmlFor="inlineCheckbox1">UX design</label>
                                            </div>
                                            <div className="form-check form-check mb-3 me-5">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                       value="User research" name="user_research"/>
                                                <label className="form-check-label contact-checklbl"
                                                       htmlFor="inlineCheckbox1">User research</label>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="form-check form-check mb-3 me-5">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2"
                                                       value="Content creation" name="content_creation"/>
                                                <label className="form-check-label contact-checklbl"
                                                       htmlFor="inlineCheckbox2">Content creation</label>
                                            </div>
                                            <div className="form-check form-check mb-3 me-5">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2"
                                                       value="Strategy & consulting" name="strategy"/>
                                                <label className="form-check-label contact-checklbl"
                                                       htmlFor="inlineCheckbox2">Strategy & consulting</label>
                                            </div>
                                            <div className="form-check form-check me-5">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2"
                                                       value="Other" name="other"/>
                                                <label className="form-check-label contact-checklbl"
                                                       htmlFor="inlineCheckbox2">Other</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {error.error &&
                            <div className="alert alert-danger" role="alert">
                                {error.errorMessage}
                            </div>}
                            {success.success &&
                            <div className="alert alert-success" role="alert">
                                {success.successMessage}
                            </div>}
                            <div className="d-grid mt-4">
                                <button className="btn btn-lg red-button contact-sendbtn pc-send-ml" type="submit">
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="container" style={{marginTop: "10vh"}}>
                    {/* <div>
                        <h3 className="contact-growtitle">Growing beyond borders</h3>
                        <p className="contact-growsubtitle">
                            Delivering digital solutions wherever you are by connecting clients and talents across
                            borders, currently present in over 15 countries and counting.
                        </p>
                    </div> */}
                    {/* <div className="contact-map"> */}
                        {/*<img src={Location} alt="Location" className="location america" data-tooltip-id="america"*/}
                        {/*     data-tooltip-content="USA"/>*/}
                        {/*<Tooltip id="america" style={{*/}
                        {/*    backgroundColor: "#EDE5FC",*/}
                        {/*    color: "#181F26",*/}
                        {/*    fontWeight: 600,*/}
                        {/*    fontSize: 12*/}
                        {/*}}/>*/}
                        {/*<img src={Location} alt="Location" className="location sri-lanka" data-tooltip-id="sri-lanka"*/}
                        {/*     data-tooltip-content="Sri Lanka"/>*/}
                        {/*<Tooltip id="sri-lanka" style={{*/}
                        {/*    backgroundColor: "#EDE5FC",*/}
                        {/*    color: "#181F26",*/}
                        {/*    fontWeight: 600,*/}
                        {/*    fontSize: 12*/}
                        {/*}}/>*/}
                        {/*<img src={Location} alt="Location" className="location australia" data-tooltip-id="australia"*/}
                        {/*     data-tooltip-content="Australia"/>*/}
                        {/*<Tooltip id="australia" style={{*/}
                        {/*    backgroundColor: "#EDE5FC",*/}
                        {/*    color: "#181F26",*/}
                        {/*    fontWeight: 600,*/}
                        {/*    fontSize: 12*/}
                        {/*}}/>*/}
                        {/*<img src={Location} alt="Location" className="location new-zealand"*/}
                        {/*     data-tooltip-id="new-zealand"*/}
                        {/*     data-tooltip-content="New Zealand"/>*/}
                        {/*<Tooltip id="new-zealand" style={{*/}
                        {/*    backgroundColor: "#EDE5FC",*/}
                        {/*    color: "#181F26",*/}
                        {/*    fontWeight: 600,*/}
                        {/*    fontSize: 12*/}
                        {/*}}/>*/}
                    {/* </div> */}
                </div>

                <TalkToUs/>

            </div>
        </div>
    )
}

export default ContactUs