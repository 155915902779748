import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import "../assets/css/Capabilities.css";

import TalkToUs from "../components/TalkToUs";
import Loading from "../components/Loading";

import {loadCategories,loadAllCaseStudies} from "../common/Common";

import Title from "../assets/images/aboutheader.jpg";
import Icon1 from "../assets/images/Icon4.svg";
import Icon2 from "../assets/images/Icon6.svg";
import Icon3 from "../assets/images/icon5.svg";
import Icon4 from "../assets/images/Icon7.svg";
import Icon5 from "../assets/images/Icon8.svg";
import Icon6 from "../assets/images/Icon9.svg";

function Capabilities({count, parentUpdateCount}) {
    const [categories, setCategories] = useState([]),
        [selectedCategory, setSelectedCategory] = useState(null),
        [windowWidth, setWindowWidth] = useState(0),
        [caseStudies, setCaseStudies] = useState([]),
        [categoryLink, setCategoryLink] = useState(decodeURI(window.location.href.split('/').at(-1))),
        [loadingContent, setLoadingContent] = useState(true),
        [loadingCaseStudyContent, setLoadingCaseStudyContent] = useState(true),
        navigate = useNavigate();

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        const tempCategoryLink = decodeURI(window.location.href.split('/').at(-1));
        setCategoryLink(tempCategoryLink);
        setLoadingContent(true);
        loadCategories().then(data => {
            setCategories(data);
            let index = data.findIndex(function (category) {
                return category.link === tempCategoryLink;
            });
            setSelectedCategory(data[index]);
            setLoadingContent(false);
        }).catch(() => null);
    }, [count]);

    useEffect(() => {
        loadAllCaseStudies().then(data => {
            setCaseStudies(data);
            setLoadingCaseStudyContent(false);
        }).catch(() => null);
    }, []);

    const resizeWindow = () => {
        setWindowWidth(window.outerWidth);
    };

    const mobileBreakpoint = 678;
    const isMobile = windowWidth < mobileBreakpoint
    const caseStudyBody = () => {
        let body = [];
        if (caseStudies && selectedCategory) {
            const filteredCaseStudies = caseStudies.filter(caseStudy => caseStudy.category.id === selectedCategory.id);
            
            const numCaseStudies = filteredCaseStudies.length;
            const firstHalf = filteredCaseStudies.slice(0, Math.ceil(numCaseStudies / 2));
            const secondHalf = filteredCaseStudies.slice(Math.ceil(numCaseStudies / 2));
    
            // Function to render a column of case studies
            const renderColumn = (studies) => {
                return (
                    <div className="column col-spc-csd">
                        {studies.map((caseStudy, index) => (
                            <div className="d-flex flex-row justify-content-start csd gap-5 ms-5 spc-flex-case-study" key={"caseStudy" + index}>
                                <div className="d-block-inline cse-txt">
                                    {/* <h6 className="case-study-category" style={{
                                        color: caseStudy.category.color,
                                        backgroundColor: caseStudy.category.color + "10",
                                        border: caseStudy.category.color
                                    }}>
                                        {caseStudy.category_tag}
                                    </h6> */}
                                    <h3 className="case-study-name">{caseStudy.heading}</h3>
                                    <p className="case-study-description mt-4">{caseStudy.description}</p>
                                </div>
                                <Link className="d-block spc-relative" to={"/case-studies/" + caseStudy.heading}>
                                    <img src={caseStudy.header_image_url} alt={caseStudy.heading} className="w-100 float-end case-study-image" />
                                    <h4 className='abs-txt-spc'>View Project</h4>
                                </Link>
                            </div>
                        ))}
                    </div>
                );
            };
    
            // Render the columns
            
            body.push(
                <div className="d-flex justify-content-between spc-render-main" key="columns">
                    {renderColumn(firstHalf)}
                    {renderColumn(secondHalf)}
                </div>
            );
        }
        return body;
    }
    // const subServiceBody = () => {
    //     let body = [];
    //     if (selectedCategory && selectedCategory.services.length > 0 && selectedCategory.services[0].sub_services) {
    //         selectedCategory.services[0].sub_services.forEach((subService, index) => {
    //             if (windowWidth > 1000) {
    //                 if (selectedCategory.services[0].sub_services.length % 3 !== 0) {
    //                     if (selectedCategory.services[0].sub_services.length - index < 3 && (index + 1) % 3 === 1) {
    //                         body.push(
    //                             <div className="col extra-service-col" key={"extraCol" + index}/>
    //                         );
    //                     }
    //                 }
    //             } else if (windowWidth > 768) {
    //                 if (selectedCategory.services[0].sub_services.length % 2 !== 0) {
    //                     if (selectedCategory.services[0].sub_services.length - index < 2 && (index + 1) % 2 === 1) {
    //                         body.push(
    //                             <div className="col extra-service-col" key={"extraCol" + index}/>
    //                         );
    //                     }
    //                 }
    //             }
    //             body.push(
    //                 <div className="col sub-serve-div2" key={"subService" + index}>
    //                     <img src={subService.image_url} className="card-img-top mx-auto d-block spc-lft-div"
    //                          alt={subService.heading}
    //                          style={{width: "48px", height: "48px", objectFit: "cover"}}/>
    //                     <div className="card-body spc-card-bdy">
    //                         <h5 className="about-card-title spc-abt-crd-ttl">{subService.heading}</h5>
    //                         <p className="about-card-text text-center spc-abt-crd-pr">
    //                             {subService.description}
    //                         </p>
    //                     </div>
    //                 </div>
    //             );
    //         });
    //     }
    //     return body;
    // }
    const subServiceBody = () => {
        let body = [];
        let subServiceCount = 0;
    
        if (selectedCategory && selectedCategory.services.length > 0 && selectedCategory.services[0].sub_services) {
            selectedCategory.services[0].sub_services.forEach((subService, index) => {
                // Limit the number of sub-serve-div2 divs to 3
                if (subServiceCount < 3) {
                    if (windowWidth > 1000) {
                        if (selectedCategory.services[0].sub_services.length % 3 !== 0) {
                            if (selectedCategory.services[0].sub_services.length - index < 3 && (index + 1) % 3 === 1) {
                                body.push(
                                    <div className="col extra-service-col" key={"extraCol" + index}/>
                                );
                            }
                        }
                    } else if (windowWidth > 768) {
                        if (selectedCategory.services[0].sub_services.length % 2 !== 0) {
                            if (selectedCategory.services[0].sub_services.length - index < 2 && (index + 1) % 2 === 1) {
                                body.push(
                                    <div className="col extra-service-col" key={"extraCol" + index}/>
                                );
                            }
                        }
                    }
    
                    body.push(
                        <div className="col sub-serve-div2" key={"subService" + index}>
                            <img src={subService.image_url} className="card-img-top mx-auto d-block spc-lft-div"
                                 alt={subService.heading}
                                 style={{width: "48px", height: "48px", objectFit: "cover"}}/>
                            <div className="card-body spc-card-bdy">
                                <h5 className="about-card-title spc-abt-crd-ttl">{subService.heading}</h5>
                                <p className="about-card-text text-center spc-abt-crd-pr">
                                    {subService.description}
                                </p>
                            </div>
                        </div>
                    );
    
                    subServiceCount++;
                }
            });
        }
        return body;
    }
    
    if (!loadingContent) {
        return (
            <div>
                <div className="panel-page-format">
                    <div className="capabilities-header-container about-us-header-container spc-cap-contain"
                         style={{paddingTop: "25vh"}}>
                        <div className="container">
                            {selectedCategory &&
                            <div>
                                <h1 className="about-title mb-4 spc-txt-hdn">
                                    {selectedCategory.services.length > 0 && selectedCategory.services[0].heading}
                                </h1>
                                <p className="about-subtitle spc-txt-sub">
                                    {selectedCategory.services.length > 0 && selectedCategory.services[0].sub_heading}
                                </p>
                            </div>
                            }
                        </div>
                    </div>

                    <div style={{backgroundColor: selectedCategory.color + "10 " }}>
                        <div className="container" style={{marginTop: "5vh"}}>
                            {selectedCategory &&
                                <div className="capability-container" style={{marginTop: "0vh"}}>
                                    <div className="row row-cols-3 g-4 pt-5 pb-5 gap-for-clr gap-for-spc-sub" style={{marginTop: "0vh"}}>
                                        {subServiceBody()}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="container category-header-container" style={{marginTop: "10vh"}}>
                        <div className='flx-case-wrapper spc-2'>
                            {loadingCaseStudyContent ?
                                <Loading/>
                                : caseStudyBody()
                            }
                        </div>
                    </div>
                    <TalkToUs/>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default Capabilities;
